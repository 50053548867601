import React, { Component } from 'react';

import AsyncCreatableSelect from 'react-select/async-creatable';
import { ActionMeta, MultiValue as MultiValue1, components } from 'react-select';
import { searchBranchByCompanyId } from '../util/Fetch';
import { BranchType, CompanyType } from '../../types';

interface State {
  readonly isLoading: boolean;
  readonly options: readonly BranchType[];
  readonly value: MultiValue1<BranchType>;
}

const promiseOptions = async (inputValue: string, company: CompanyType) => {
    let branches = await searchBranchByCompanyId(company.id, inputValue);

    return branches.data?.map((branch: BranchType) => branch) ?? [];
};
const Control = (props: any) => (
  <components.Control 
     {...props} 
     className="bg-white mt-1 flex flex-row focus:ring-indigo-500 focus:border-indigo-500 block w-full px-3 py-2	sm:text-sm border border-gray-300 rounded-md"
  />
)

const ValueContainer = (props: any) => (
  <components.ValueContainer 
     {...props} 
     className="flex-1 flex flex-rows"
  />
)

const Menu = (props: any) => (
  <components.Menu 
     {...props} 
     className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 px-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
  />
)

const Option = (props: any) => (
  <components.Option
     {...props}    
     className="hover:bg-indigo-500 hover:text-white rounded-md cursor-pointer relative cursor-default select-none block p-3 text-gray-900"
  />
)

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator 
     {...props} 
     className="inset-y-0 right-0 flex items-center rounded-r-md focus:outline-none css-0"
  >
    <svg viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-gray-400">
      <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd"></path>
    </svg>
  </components.DropdownIndicator>
)

const ClearIndicator = (props: any) => (
  <components.ClearIndicator 
     {...props} 
     className="inset-y-0 right-0 flex items-center rounded-r-md focus:outline-none css-0"
  >
    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="h-5 w-5 text-gray-400">
      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
    </svg>
  </components.ClearIndicator>
)

const IndicatorsContainer = (props: any) => (
  <components.IndicatorsContainer 
     {...props} 
     className="flex flex-rows items-start"
  />
)

const Placeholder = (props: any) => (
  <components.Placeholder 
     {...props} 
     className="absolute"
  />
)

const Input = (props: any) => (
  <components.Input 
     {...props} 
     className="flex-1"
     inputClassName="outline-none border-none shadow-none focus:ring-transparent"
  />
)

const SingleValue = (props: any) => (
  <components.SingleValue 
     {...props} 
  />
)

const MultiValue = (props: any) => (
  <components.MultiValue 
     {...props} 
     className="inline-flex items-center mr-0.5 px-0.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
  />
)

const styleProxy = new Proxy(
  {},
  {
    get: (target, propKey) => () => {}
  }
);

export default class Branch extends Component<{company: CompanyType, value: BranchType[], onChanged(value: BranchType[]): void }, State> {
  state: State = {
    isLoading: false,
    options: [],
    value: this.props.value,
  };

  handleChange = (
    newValue: MultiValue1<BranchType>,
    actionMeta: ActionMeta<BranchType>
  ) => {
    this.setState({ value: newValue }, () => this.props.onChanged(newValue as BranchType[]));
  };

  render() {
    const { isLoading, options, value } = this.state;

    return (
      <AsyncCreatableSelect
        isClearable={false}
        isMulti
        styles={styleProxy}
        className='relative'
        components={{ MultiValue, SingleValue, ClearIndicator, Option, Menu, IndicatorsContainer, DropdownIndicator, Placeholder, Input, ValueContainer, Control }}
        isDisabled={isLoading}
        isLoading={isLoading}
        onChange={this.handleChange}
        options={options}
        getOptionLabel={(option: BranchType)=> option.name}
        getOptionValue={(option: BranchType)=> option.id}
        cacheOptions
        defaultOptions
        loadOptions={(value:string) => promiseOptions(value, this.props.company)}
        value={value}
      />
    );
  }
}