import React, { createContext, Dispatch, useReducer } from "react";
import { ActionMap, BranchAreaType, BranchType } from "../../types";

type CompanyStateType = {
  branches: BranchType[];
  areas: BranchAreaType[],
};

enum CompanyActionTypes {
  LoadBranches = "LOAD_BRANCHES",
  AddBranch = "ADD_BRANCH",
  AddBranchArea = "ADD_AREA",
  EditBranch = "EDIT_BRANCH",
  EditArea = "EDIT_AREA",
}

type CompanyPayload = {
  [CompanyActionTypes.LoadBranches]: {
    branches: BranchType[];
  },
  [CompanyActionTypes.AddBranch]: {
    branch: BranchType;
  }
  [CompanyActionTypes.AddBranchArea]: {
    area: BranchAreaType;
  }
  [CompanyActionTypes.EditBranch]: {
    branch: BranchType;
  }
  [CompanyActionTypes.EditArea]: {
    area: BranchAreaType;
  }
};

type CompanyActions = ActionMap<CompanyPayload>[keyof ActionMap<CompanyPayload>];

const companyIntialState = {
  branches: [],
  areas: [],
};

const companyReducer = (prevState: CompanyStateType, action: CompanyActions) => {
  switch (action.type) {
    case CompanyActionTypes.LoadBranches:
      return {
        ...prevState,
        ...action.payload
      };
    case CompanyActionTypes.AddBranch:
      return {
        ...prevState,
        ...[...prevState.branches, ...[action.payload.branch]]
      };
    case CompanyActionTypes.AddBranchArea:
      return {
        ...prevState,
        ...[...prevState.areas, ...[action.payload.area]]
      };
    case CompanyActionTypes.EditBranch:
      let branches = prevState.branches;

      branches = branches.map(b => b.id !== action.payload.branch.id ? b : action.payload.branch)

      return {
        ...prevState,
        ...branches
      };
    case CompanyActionTypes.EditArea:
      let areas = prevState.areas;

      areas = areas.map(b => b.id !== action.payload.area.id ? b : action.payload.area)

      return {
        ...prevState,
        ...areas
      };
    default:
      return prevState;
  }
};

const CompanyContext = createContext<{
  state: CompanyStateType;
  dispatch: Dispatch<CompanyActions>;
}>({
  state: companyIntialState,
  dispatch: () => null,
});

const CompanyProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(companyReducer, companyIntialState);
  return <CompanyContext.Provider value={{ state, dispatch }}>{children}</CompanyContext.Provider>;
};

export { CompanyContext, companyReducer, companyIntialState, CompanyProvider, CompanyActionTypes };
