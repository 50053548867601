import React, { FormEvent, Fragment, useContext, useEffect, useRef, useState } from "react";
import {
    useNavigate,
} from "react-router-dom";

import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

import { AuthContext } from "../../context/UserProvider";

import Category from "../../layout/category";
import DragAndDrop from "../../layout/dragAndDrop";

import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";

import { BranchType, TaxType, CategoryType, ArticleIngredientsType } from "../../../types";
import { createArticle, searchTaxByCompanyId } from "../../util/Fetch";
import Branch from "../../layout/branch";
import { ArticleActionTypes, ArticleContext } from "../../context/ArticelsProvider";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function ArticleAdd() {
    const { state: { company } } = useContext(AuthContext);
    const ArticleContextData = useContext(ArticleContext);

    let navigate = useNavigate();

    const [ingredientsAdd, setIngredientsAdd] = useState<boolean>(false);
    
    const [articleName, setArticleName] = useState<string>('');
    const [articleOrderNumber, setArticleOrderNumber] = useState<string>('');
    const [articleWeigth, setArticleWeigth] = useState<number>(0);
    const [note, setNote] = useState<string>('');
    const [articlePrice, setArticlePrice] = useState<number>(0);
    const [category, setCategory] = useState<CategoryType|null>(null);
    // const [ingredients, setIngredients] = useState<IngredientsType[]>([]);
    const [branches, setBranches] = useState<BranchType[]>([]);
    const [articleIngredients, setArticleIngredients] = useState<ArticleIngredientsType[]>([]);

    const [inHouseTax, setInHouseTax] = useState<TaxType|null>(null);
    const [outHouseTax, setOutHouseTax] = useState<TaxType|null>(null);

    const [articleFile1, setArticleFile1] = useState<File|null>(null);
    const [articleFile2, setArticleFile2] = useState<File|null>(null);
    const [articleFile3, setArticleFile3] = useState<File|null>(null);
    const [articleFile4, setArticleFile4] = useState<File|null>(null);
    
    // const [createIngredientName, setCreateIngredientName] = useState<string>('');
    // const [createIngredientFat, setCreateIngredientFat] = useState<string>('');
    // const [createIngredientFattyAcid, setCreateIngredientFattyAcid] = useState<string>('');
    // const [createIngredientCarbohydrates, setCreateIngredientCarbohydrates] = useState<string>('');
    // const [createIngredientSugar, setCreateIngredientSugar] = useState<string>('');
    // const [createIngredientProtein, setCreateIngredientProtein] = useState<string>('');
    // const [createIngredientSalt, setCreateIngredientSalt] = useState<string>('');
    const [articleFat, setArticleFat] = useState<string>('0');
    const [articleFattyAcid, setArticleFattyAcid] = useState<string>('0');
    const [articleCarbohydrates, setArticleCarbohydrates] = useState<string>('0');
    const [articleSugar, setArticleSugar] = useState<string>('0');
    const [articleProtein, setArticleProtein] = useState<string>('0');
    const [articleSalt, setArticleSalt] = useState<string>('0');
    
    const taxes = useRef<TaxType[]>([]);

    useEffect(() => {
        const inti = () => {
            getTaxes();
        }

        inti();
    });

    // const saveIngredient = async() => {
    //     if (company) {
    //         let ingredientsRequest = await post<IngredientsType>(`${process.env.REACT_APP_API_URL}/api/v1/${company.id}/ingredients/new`, {
    //             ingredients: {
    //                 name: createIngredientName,
    //                 weight: 100,
    //                 fat: createIngredientFat,
    //                 fattyAcid: createIngredientFattyAcid,
    //                 carbohydrates: createIngredientCarbohydrates,
    //                 sugar: createIngredientSugar,
    //                 protein: createIngredientProtein,
    //                 salt: createIngredientSalt,
    //                 enabled: true,
    //             }
    //         });
        
    //         if (ingredientsRequest.data) {
    //             setIngredientsAdd(false);
    //             setCreateIngredientName('');
    //             setCreateIngredientFat('');
    //             setCreateIngredientFattyAcid('');
    //             setCreateIngredientCarbohydrates('');
    //             setCreateIngredientSugar('');
    //             setCreateIngredientProtein('');
    //             setCreateIngredientSalt('');
    //             setArticleIngredients([...articleIngredients, ...[{ ingredients: ingredientsRequest.data, weight: 0, enabled: true }]]);
    //         }
    //     }
    // }
 
    const getTaxes = async () => {
        if (company === null) return;

        let taxesList = await searchTaxByCompanyId(company.id, '*');
        if (taxesList.data) {
            taxes.current = taxesList.data;

            setInHouseTax(taxes.current[0]);
            setOutHouseTax(taxes.current[0]);
        }
    };

    // const addIngredient = (ingredients: IngredientsType) => {
    //     setArticleIngredients([...articleIngredients, ...[{ ingredients, weight: 0, enabled: true}]]);
    // }

    const updateArticleIngredients = (searchArticleIngredients: ArticleIngredientsType, weight:string) => {
        let index = articleIngredients.findIndex((ai) => ai.ingredients.id === searchArticleIngredients.ingredients.id);

        let newArticleIngredients = articleIngredients;
        newArticleIngredients[index].weight = parseFloat(weight);

        setArticleIngredients(newArticleIngredients);
        calculateArticleIngredients();
    }

    const calculateArticleIngredients = () => {
        let data = articleIngredients.map((ai) => ({
            carbohydrates: ai.weight / 100 * ai.ingredients.carbohydrates,
            fat: ai.weight / 100 * ai.ingredients.fat,
            fattyAcid: ai.weight / 100 * ai.ingredients.fattyAcid,
            protein: ai.weight / 100 * ai.ingredients.protein,
            salt: ai.weight / 100 * ai.ingredients.salt,
            sugar: ai.weight / 100 * ai.ingredients.sugar,
        }));

        let carbohydrates = data.reduce((preValue, d) => preValue + d.carbohydrates, 0);
        let fat = data.reduce((preValue, d) => preValue + d.fat, 0);
        let fattyAcid = data.reduce((preValue, d) => preValue + d.fattyAcid, 0);
        let protein = data.reduce((preValue, d) => preValue + d.protein, 0);
        let salt = data.reduce((preValue, d) => preValue + d.salt, 0);
        let sugar = data.reduce((preValue, d) => preValue + d.sugar, 0);

        setArticleFat(fat.toString());
        setArticleFattyAcid(fattyAcid.toString());
        setArticleCarbohydrates(carbohydrates.toString());
        setArticleSugar(sugar.toString());
        setArticleProtein(protein.toString());
        setArticleSalt(salt.toString());
    }

    // const delArticleIngredients = (index: number) => {

    //     let newArticleIngredients = articleIngredients;

    //     newArticleIngredients.splice(index, 1);

    //     setArticleIngredients(newArticleIngredients);
    //     calculateArticleIngredients();
    // }

    const saveArticle = async(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (category === null || company === null || inHouseTax === null || outHouseTax === null) {

            return;
        }

        const formData = new FormData();
        formData.append('article[name]', articleName);
        formData.append('article[price]', articlePrice.toString());
        formData.append('article[orderNumber]', articleOrderNumber);
        formData.append('article[note]', note.toString());
        formData.append('article[weight]', articleWeigth.toString());
        formData.append('article[fat]', articleFat);
        formData.append('article[fattyAcid]', articleFattyAcid);
        formData.append('article[carbohydrates]', articleCarbohydrates);
        formData.append('article[sugar]', articleSugar);
        formData.append('article[protein]', articleProtein);
        formData.append('article[salt]', articleSalt);
        formData.append('article[category]', category.id);
        formData.append(`article[inHouseTax]`, inHouseTax.id);
        formData.append(`article[outHouseTax]`, outHouseTax.id);

        let imageCount = 0;

        if (articleFile1) {
            formData.append(`article[images][${imageCount}][imageFile][file]`, articleFile1);
            formData.append(`article[images][${imageCount}][sort]`, (imageCount + 1).toString());

            imageCount++;
        }

        if (articleFile2) {
            formData.append(`article[images][${imageCount}][imageFile][file]`, articleFile2);
            formData.append(`article[images][${imageCount}][sort]`, (imageCount + 1).toString());

            imageCount++;
        }

        if (articleFile3) {
            formData.append(`article[images][${imageCount}][imageFile][file]`, articleFile3);
            formData.append(`article[images][${imageCount}][sort]`, (imageCount + 1).toString());

            imageCount++;
        }

        if (articleFile4) {
            formData.append(`article[images][${imageCount}][imageFile][file]`, articleFile4);
            formData.append(`article[images][${imageCount}][sort]`, (imageCount + 1).toString());

            imageCount++;
        }
    
        if (articleIngredients) {
            for (const key in articleIngredients) {
                formData.append(`article[articleIngredients][${key}][ingredients]`, articleIngredients[key].ingredients.id);
                formData.append(`article[articleIngredients][${key}][weight]`, articleIngredients[key].weight.toString());
                formData.append(`article[articleIngredients][${key}][enabled]`, '1');
            }
        }

        if (branches) {
            for (const key in branches) {
                formData.append(`article[branchArticle][${key}][branch]`, branches[key].id);
                formData.append(`article[branchArticle][${key}][inHouseTax]`, inHouseTax.id);
                formData.append(`article[branchArticle][${key}][outHouseTax]`, outHouseTax.id);
                formData.append(`article[branchArticle][${key}][price]`, articlePrice.toString());
                formData.append(`article[branchArticle][${key}][enabled]`, '1');
                
            }
        }

        formData.append('article[enabled]', '1');

        try {
            let article = await createArticle(company.id, formData);

            if (article.data && article.data.status === true && article.data.data) {
                ArticleContextData.dispatch({ type: ArticleActionTypes.AddArticle, payload: { article: article.data.data}})
                navigate('/articles');
            }
        } catch (error) {
            
        }
    }

    if (company === null) {
        return <div></div>;
    }

    return (
        <form onSubmit={saveArticle} className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200">
                <div>
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Produkt</h3>
                        <p className="mt-1 text-sm text-gray-500">
                        This information will be displayed publicly so be careful what you share.
                        </p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={(event) => setArticleName(event.target.value)}
                                    autoComplete="name"
                                    className="flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Category
                            </label>
                            <Category value={category} onChanged={(value) => setCategory(value)} company={company} />
                        </div>
                        <div className="sm:col-span-6">
                            <label htmlFor="short_description" className="block text-sm font-medium text-gray-700">
                                Kurz Beschreibung
                            </label>
                            <div className="mt-1">
                                <textarea
                                id="short_description"
                                name="short_description"
                                rows={1}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                                defaultValue={''}
                                />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">Listen Beschreibung</p>
                        </div>
                        <div className="sm:col-span-6">
                            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                                Beschreibung
                            </label>
                            <div className="mt-1">
                                <textarea
                                id="description"
                                name="description"
                                rows={3}
                                onChange={(event) => setNote(event.target.value)}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                                defaultValue={''}
                                value={note ?? ''}
                                />
                            </div>
                            <p className="mt-2 text-sm text-gray-500">Schreibe etwas zu deinem Produkt</p>
                        </div>
                        <div className="sm:col-span-6">
                            <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700">
                                Fotos
                            </label>
                            <div className="mt-1 flex flex-rows justify-start gap-x-2">
                                <DragAndDrop isSingle handleDrop={(files) => setArticleFile1(files[0])} className={''}>
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                    <span>Upload a file</span>
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">
                                                PNG, JPG, GIF up to 10MB
                                            </p>
                                        </div>
                                    </div>
                                </DragAndDrop>
                                <DragAndDrop isSingle handleDrop={(files) => setArticleFile2(files[0])} className={''}>
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                    <span>Upload a file</span>
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">
                                                PNG, JPG, GIF up to 10MB
                                            </p>
                                        </div>
                                    </div>
                                </DragAndDrop>
                                <DragAndDrop isSingle handleDrop={(files) => setArticleFile3(files[0])} className={''}>
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                    <span>Upload a file</span>
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">
                                                PNG, JPG, GIF up to 10MB
                                            </p>
                                        </div>
                                    </div>                                        
                                </DragAndDrop>
                                <DragAndDrop isSingle handleDrop={(files) => setArticleFile4(files[0])} className={''}>
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                    <span>Upload a file</span>
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500">
                                                PNG, JPG, GIF up to 10MB
                                            </p>
                                        </div>
                                    </div>
                                </DragAndDrop>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-8">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Kosten Informationen</h3>
                        <p className="mt-1 text-sm text-gray-500">Use a permanent address where you can receive mail.</p>
                    </div>
                    <div className="mt-6 sm:col-span-4">
                        <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                            Preis
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                                type="text"
                                name="price"
                                id="price"
                                onChange={(event) => setArticlePrice(parseFloat(event.target.value))}
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                placeholder="0.00"
                                aria-describedby="price-currency"
                            />
                        </div>
                    </div>
                    <div className="mt-6 grid grid-cols-6 gap-y-6 gap-x-4 sm:grid-cols-9">
                        <div className="sm:col-span-3">
                            <label htmlFor="orderNumber" className="block text-sm font-medium text-gray-700">
                                Bestellnummer
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="number"
                                    name="orderNumber"
                                    id="orderNumber"
                                    onChange={(event) => setArticleOrderNumber(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-5 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0"
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <Listbox value={inHouseTax} onChange={setInHouseTax}>
                                {({ open }) => (
                                    <>
                                    <Listbox.Label className="block text-sm font-medium text-gray-700">In House Tax</Listbox.Label>
                                    <div className="mt-1 relative">
                                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                        <span className="block truncate">{inHouseTax?.name ?? ''}</span>
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                            {taxes.current.map((tax) => (
                                            <Listbox.Option
                                                key={tax.id}
                                                className={({ active }) =>
                                                classNames(
                                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                                )
                                                }
                                                value={tax}
                                            >
                                                {({ selected, active }) => (
                                                <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                    {tax.name}
                                                    </span>

                                                    {selected ? (
                                                    <span
                                                        className={classNames(
                                                        active ? 'text-white' : 'text-indigo-600',
                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                    ) : null}
                                                </>
                                                )}
                                            </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                        </Transition>
                                    </div>
                                    </>
                                )}
                            </Listbox>
                        </div>
                        <div className="sm:col-span-3">
                            <Listbox value={outHouseTax} onChange={setOutHouseTax}>
                                {({ open }) => (
                                    <>
                                    <Listbox.Label className="block text-sm font-medium text-gray-700">Out House Tax</Listbox.Label>
                                    <div className="mt-1 relative">
                                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                        <span className="block truncate">{outHouseTax?.name ?? ''}</span>
                                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                            {taxes.current.map((tax) => (
                                            <Listbox.Option
                                                key={tax.id}
                                                className={({ active }) =>
                                                classNames(
                                                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                                )
                                                }
                                                value={tax}
                                            >
                                                {({ selected, active }) => (
                                                <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                    {tax.name}
                                                    </span>

                                                    {selected ? (
                                                    <span
                                                        className={classNames(
                                                        active ? 'text-white' : 'text-indigo-600',
                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                    ) : null}
                                                </>
                                                )}
                                            </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                        </Transition>
                                    </div>
                                    </>
                                )}
                            </Listbox>
                        </div>
                        <div className="sm:col-span-9">
                            <label htmlFor="street-address" className="block text-sm font-medium text-gray-700">
                                Standort
                            </label>
                            <div className="mt-1">
                                <Branch value={branches} onChanged={(value) => setBranches(value)} company={company} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-8">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Produkt Detail</h3>
                        <p className="mt-1 text-sm text-gray-500">
                        We'll always let you know about important changes, but you pick what else you want to hear about.
                        </p>
                    </div>
                    <div className="mt-6 grid grid-cols-6 gap-y-6 gap-x-4 sm:grid-cols-9">
                        <div className="sm:col-span-3">
                            <label htmlFor="weigth" className="block text-sm font-medium text-gray-700">
                                Gewicht
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="weigth"
                                    id="weigth"
                                    onChange={(event) => setArticleWeigth(parseFloat(event.target.value))}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0.0"
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 font-bold sm:text-sm">g.</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 grid grid-cols-6 gap-y-6 gap-x-4 sm:grid-cols-9">
                        <div className="sm:col-span-6">
                            <span>{'Zutaten'}</span>
                        </div>
                        {articleIngredients.map((i, index) => (
                            <div className="sm:col-span-3">
                                <label htmlFor="weigth" className="block text-sm font-medium text-gray-700">
                                    {i.ingredients.name}
                                </label>
                                <div className="mt-1 relative rounded-md shadow-sm">
                                    <input
                                        type="text"
                                        name="weigth"
                                        id="weigth"
                                        onChange={(event) => updateArticleIngredients(i, event.target.value)}
                                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                        placeholder="0.0"
                                    />
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                        <span className="text-gray-500 font-bold sm:text-sm">G.</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        { articleIngredients.length === 0 && <div className={'sm:col-span-6'} onClick={() => setIngredientsAdd(true)}>noch keine Zutaten, <span>jetzt Zutaten hinzufügen</span></div>}
                        { articleIngredients.length > 0 && <div className={'sm:col-span-6'} onClick={() => setIngredientsAdd(true)}>weitere Zutaten</div>}
                        {ingredientsAdd && (
                            <Dialog
                                aria-labelledby="label"
                                onDismiss={() => setIngredientsAdd(false)}
                            >
                          {/*       <div className={'search-bar'}>
                                    <SearchBar placeholder={'nach Zutaten suchen'} onResulte={(resulte:IngredientsType[]) => setIngredients(resulte)} company={company} />
                                </div>
                                <div className={'search-resulte'}>
                                    {ingredients.map((i) => (
                                        <div className={`item ${articleIngredients.length > 0 && articleIngredients.findIndex((ac) => ac.ingredients.id === i.id) !== -1 ? 'dis' : ''}`} onClick={() => articleIngredients.findIndex((ac) => ac.ingredients.id === i.id) === -1 && addIngredient(i)}>
                                            <div className={'name'}>
                                                {i.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className={'ingredients-create'}>
                                    <div className={'ingredients-create-title'}>
                                        <h1>Zutaten <span>pro 100g</span></h1>
                                    </div>
                                    <div className={'ingredient-name'}>
                                        <InputView labelText="Name" placeholder={'Name'} type={'text'} value={createIngredientName} onChange={(value) => setCreateIngredientName(value)} />
                                    </div>
                                    <div className={'colum'}>
                                        <div className={'ingredient-fat'}>
                                            <InputView labelText="Fett" placeholder={'0'} addText={'g'} type={'number'} value={createIngredientFat} onChange={(value) => setCreateIngredientFat(value)} />
                                        </div>
                                        <div className={'ingredient-fattyAcid'}>
                                            <InputView labelText="Fett" placeholder={'0'} addText={'g'} type={'number'} value={createIngredientFattyAcid} onChange={(value) => setCreateIngredientFattyAcid(value)} />
                                        </div>
                                        <div className={'ingredient-carbohydrates'}>
                                            <InputView labelText="Kohlehydrate" placeholder={'0'} addText={'g'} type={'number'} value={createIngredientCarbohydrates} onChange={(value) => setCreateIngredientCarbohydrates(value)} />
                                        </div>
                                    </div>
                                    <div className={'colum'}>
                                        <div className={'ingredient-sugar'}>
                                            <InputView labelText="Zucker" placeholder={'0'} addText={'g'} type={'number'} value={createIngredientSugar} onChange={(value) => setCreateIngredientSugar(value)} />
                                        </div>
                                        <div className={'ingredient-protein'}>
                                            <InputView labelText="Eiweiß" placeholder={'0'} addText={'g'} type={'number'} value={createIngredientProtein} onChange={(value) => setCreateIngredientProtein(value)} />
                                        </div>
                                        <div className={'ingredient-salt'}>
                                            <InputView labelText="Salz" placeholder={'0'} addText={'g'} type={'number'} value={createIngredientSalt} onChange={(value) => setCreateIngredientSalt(value)} />
                                        </div>
                                    </div>
                                    <div className={'add-ingredient-box'}>
                                        <ButtonView className={'blue add-ingredient'} onClick={() => saveIngredient()}>
                                            <span>Zutaten anlegen</span>
                                        </ButtonView>
                                    </div>
                                </div> */}
                            </Dialog>
                        )}
                    </div>
                    <div className="mt-6 grid grid-cols-9 gap-y-6 gap-x-4 sm:grid-cols-9">
                        <div className="sm:col-span-3">
                            <label htmlFor="fat" className="block text-sm font-medium text-gray-700">
                                Fett
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="fat"
                                    id="fat"
                                    onChange={(event) => setArticleFat(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0"
                                    value={articleFat}
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 font-bold sm:text-sm">g.</span>
                                </div>
                            </div>
                        </div>

                        <div className="sm:col-span-3">
                            <label htmlFor="fattacid" className="block text-sm font-medium text-gray-700">
                                FattyAcid
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="fattacid"
                                    id="fattacid"
                                    onChange={(event) => setArticleFattyAcid(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0"
                                    value={articleFattyAcid}
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 font-bold sm:text-sm">g.</span>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="carbohydrates" className="block text-sm font-medium text-gray-700">
                                Kohlehydrate
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="carbohydrates"
                                    id="carbohydrates"
                                    onChange={(event) => setArticleCarbohydrates(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0"
                                    value={articleCarbohydrates}
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 font-bold sm:text-sm">g.</span>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="articleSugar" className="block text-sm font-medium text-gray-700">
                                Zucker
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="articleSugar"
                                    id="articleSugar"
                                    onChange={(event) => setArticleSugar(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0"
                                    value={articleSugar}
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 font-bold sm:text-sm">g.</span>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="protein" className="block text-sm font-medium text-gray-700">
                                Eiweiß
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="protein"
                                    id="protein"
                                    onChange={(event) => setArticleProtein(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0"
                                    value={articleProtein}
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 font-bold sm:text-sm">g.</span>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="salt" className="block text-sm font-medium text-gray-700">
                                Salz
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="salt"
                                    id="salt"
                                    onChange={(event) => setArticleSalt(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="0"
                                    value={articleSalt}
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                    <span className="text-gray-500 font-bold sm:text-sm">g.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pt-5">
                <div className="flex justify-end">
                <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Produkt anlegen
                </button>
                </div>
            </div>
        </form>
    );
}
