import React, { useEffect, useContext, useState, useRef } from "react";
import {
    useNavigate,
    useParams,
} from "react-router-dom";

import { AuthContext } from "../../context/UserProvider";
import DeckGL from '@deck.gl/react/typed';
import 'mapbox-gl/dist/mapbox-gl.css'

import { DrawPolygonMode, EditableGeoJsonLayer } from 'nebula.gl';
import { Map } from "react-map-gl";
import { editBranchDeliveryArea, getBranch } from "../../util/Fetch";
import { CompanyActionTypes, CompanyContext } from "../../context/CompanyProvider";
import { BranchType } from "../../../types";

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZ3JlZ29yb3JkaW8iLCJhIjoiY2w2cTlsaDdmMDdiZzNrcGJiNjM1ZzZ1MyJ9.iVa4UnPEWP4-r4VbILP9Hg";

export default function DeliveryArea() {
    const { state: { company } } = useContext(AuthContext);
    const CompanyContextData = useContext(CompanyContext);

    let navigate = useNavigate();
    
    const [isInitializing, setIsInitializing] = useState(true);
    const [geoShapes, setGeoShaps] = useState<any>(null);  
    const [selectedFeatureIndexes] = useState([]);

    const branch = useRef<BranchType|null>(null);

    
    let params = useParams();

    useEffect(() => {
        const fetchInitialData = async () => {
            if (company && params.branchId) {
                const branchResponse = await getBranch(company.id, params.branchId);

                if (branchResponse.data) {
                    if (branchResponse.data.deliveryGeoShape === null) {
                        const myFeatureCollection = {
                            type: 'FeatureCollection',
                            features: [
                            ],
                        };

                        setGeoShaps(myFeatureCollection);
                    } else {
                        setGeoShaps(branchResponse.data.deliveryGeoShape);
                    }

                    branch.current = branchResponse.data;
                }
            }

            setIsInitializing(false)
        };

        fetchInitialData();
    }, [company, params.branchId]);

    const edit = async () => {
        if (company === null || branch.current === null) return;

        const formData = new FormData();
        formData.append('branch_delivery_area[deliveryGeoShape]', JSON.stringify(geoShapes));

        try {
            let branchPost = await editBranchDeliveryArea(company.id, branch.current.id, formData);

            if (branchPost.data && branchPost.data.status === true) {
                CompanyContextData.dispatch({ type: CompanyActionTypes.EditBranch, payload: { branch: branchPost.data.data }})
                navigate('/branches');
            }
        } catch (error) {
            
        }
    };

    if (company === null) {
        return null;
    }

    if (isInitializing === true || branch.current === null || branch.current.latitude === null || branch.current.longitude === null) {
        return null;
    }

    // @ts-ignore
    const layer = new EditableGeoJsonLayer({
        id: 'geojson-layer',
        data: geoShapes,
        mode: DrawPolygonMode,
        selectedFeatureIndexes,
        onEdit: ({updatedData}: any) => {
          setGeoShaps(updatedData);
        },
      });
  
      const layers = [layer];

      return (
        <div className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-xl font-semibold text-gray-900">Standorte</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Standorte Standorte Standorte Standorte
                            </p>
                        </div>x
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <button
                                type="button"
                                onClick={edit}
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                                speichern
                            </button>
                        </div>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-9"></div>
                        <div style={{ position: 'relative', height: 400}}>
                            <DeckGL
                                initialViewState={{
                                    latitude: branch.current.latitude,
                                    longitude: branch.current.longitude,
                                    zoom: 11
                                }}
                                controller={{
                                    doubleClickZoom: false
                                }}
                                layers={layers as any}
                            >
                            <Map
                                mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
                                mapStyle="mapbox://styles/mapbox/light-v9"
                            />
                        </DeckGL>
                    </div>
                </div>
        </div>
      );
}
