import { BranchType, CompanyType, TaxType, UserResponse, UserType, IngredientsType, CustomHeaders, StaffType, HttpResponse, LoginResponse, CategoryType, AdditivesType, ArticleResponse, ArticleType, BranchResponse, ArticleIngredientsType, ArticleImageDelResponse, CompanyResponse, ArticleBranchType, BranchArticleResponse, BranchAreaType, BranchAreaResponse } from '../../types';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const defaultHeaders: HeadersInit & CustomHeaders = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'User-Agent': 'ordio-app',
  'App-Version': process.env?.APP_VERSION ?? '',
};

const defaultPostHeaders: HeadersInit & CustomHeaders = {
  'Accept': 'application/json',
  'User-Agent': 'ordio-app',
  'App-Version': process.env?.APP_VERSION ?? '',
};

const authenticatedHeaders = (
  token: string | null
): HeadersInit & CustomHeaders => ({
  ...defaultHeaders,
  'Authorization': `Bearer ${token}`
});

const authenticatedPostHeaders = (
  token: string | null
): HeadersInit & CustomHeaders => ({
  ...defaultPostHeaders,
  'Authorization': `Bearer ${token}`
});

const postHeaders = (
): HeadersInit & CustomHeaders => ({
  ...defaultPostHeaders,
});


const defaultRequestOptions: RequestInit = {
  credentials: 'include',
  mode: 'cors'
}

export async function http<T>(
  request: RequestInfo
): Promise<HttpResponse<T>> {
  try {
    const response: HttpResponse<T> = await fetch(request);

    if (!response.ok) {
      response.error = response.statusText;
      return response;
    }

    try {
      response.data = await response.json();
    } catch (error: any) {
      response.error = error;
    }

    return response;
  } catch (error: any) {
    // if request returns a 401 Unauthorized exception try to refresh the session token
    if (error.message === 'Unauthorized') {
        return Promise.reject(error);
    }

    return Promise.reject(error.message);
  }
}

export async function publicGet<T>(
  path: string,
  args: RequestInit = { method: "GET", headers: { ...defaultHeaders }, ...defaultRequestOptions }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function publicPost<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "POST", body: JSON.stringify(body), headers: { ...defaultHeaders }, ...defaultRequestOptions }
): Promise<HttpResponse<T>> {
  return await http<T>(new Request(path, args));
}

export async function publicPostWithFormData<T>(
  path: string,
  body: FormData,
  args: RequestInit = { method: "POST", body: body, ...defaultRequestOptions }
): Promise<HttpResponse<T>> {
  const postHeadersArgs = { ...args, headers: { ...postHeaders() } }
  return await http<T>(new Request(path, postHeadersArgs));
  
}

export async function get<T>(
  path: string,
  args: RequestInit = { method: "GET", ...defaultRequestOptions }
): Promise<HttpResponse<T>> {
  const token = await getToken();
  const authenticatedArgs = { ...args, headers: { ...authenticatedHeaders(token) } }
  return await http<T>(new Request(path, authenticatedArgs));
}

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "POST", body: JSON.stringify(body), ...defaultRequestOptions }
): Promise<HttpResponse<T>> {
  const token = await getToken();
  const authenticatedArgs = { ...args, headers: { ...authenticatedHeaders(token) } }
  return await http<T>(new Request(path, authenticatedArgs));
}

export async function postWithFormData<T>(
  path: string,
  body: FormData,
  args: RequestInit = { method: "POST", body: body, ...defaultRequestOptions }
): Promise<HttpResponse<T>> {
  const token = await getToken();
  const authenticatedArgs = { ...args, headers: { ...authenticatedPostHeaders(token) } }
  return await http<T>(new Request(path, authenticatedArgs));
}

export async function put<T>(
  path: string,
  body: any,
  args: RequestInit = { method: "PUT", body: JSON.stringify(body), ...defaultRequestOptions }
): Promise<HttpResponse<T>> {
  const token = await getToken();
  const authenticatedArgs = { ...args, headers: { ...authenticatedHeaders(token) } }
  return await http<T>(new Request(path, authenticatedArgs));
}

export async function del<T>(
  path: string,
  args: RequestInit = { method: "DELETE", ...defaultRequestOptions }
): Promise<HttpResponse<T>> {
  const token = await getToken();
  const authenticatedArgs = { ...args, headers: { ...authenticatedHeaders(token) } }
  return await http<T>(new Request(path, authenticatedArgs));
}

export async function login(email: string, password: string): Promise<HttpResponse<LoginResponse>> {
  const response = await publicPost<LoginResponse>(`${process.env.REACT_APP_API_URL}/authentication_token`, {
    email,
    password
  });

  if (response.data) {
    await setToken(response.data.token);
  }

  return response;
}

export async function getUserData(): Promise<HttpResponse<UserType>> {
  return get<UserType>(`${process.env.REACT_APP_API_URL}/api/v1/user/`)
}

export async function getCompaniesData(): Promise<HttpResponse<CompanyType[]>> {
  return get<CompanyType[]>(`${process.env.REACT_APP_API_URL}/api/v1/company/`)
}

export async function getBranchByCompanyId(companyId:string): Promise<HttpResponse<BranchType[]>> {
  return get<BranchType[]>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/branch/`)
}

export async function getStaffByCompanyId(companyId:string): Promise<HttpResponse<StaffType[]>> {
  return get<StaffType[]>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/staff/`)
}

export async function getCategoryByCompanyId(companyId:string, search:string): Promise<HttpResponse<CategoryType[]>> {
  let urlParms = new URLSearchParams();
  urlParms.append('search', search);

  return get<CategoryType[]>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/category/?${urlParms}`)
}

export async function getAdditivesByCompanyId(companyId:string, search:string): Promise<HttpResponse<AdditivesType[]>> {
  let urlParms = new URLSearchParams();
  urlParms.append('search', search);

  return get<AdditivesType[]>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/additives/?${urlParms}`)
}

export async function searchIngredientsByCompanyId(companyId:string, search:string): Promise<HttpResponse<IngredientsType[]>> {
  let urlParms = new URLSearchParams();
  urlParms.append('search', search);

  return get<IngredientsType[]>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/ingredients/?${urlParms}`)
}

export async function searchBranchByCompanyId(companyId:string, search:string): Promise<HttpResponse<BranchType[]>> {
  let urlParms = new URLSearchParams();
  urlParms.append('search', search);

  return get<BranchType[]>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/branch/?${urlParms}`)
}

export async function getArticleByOrderNumber(companyId:string, orderNumber:string): Promise<HttpResponse<ArticleType[]>> {
  let urlParms = new URLSearchParams();
  urlParms.append('order_number', orderNumber);

  return get<ArticleType[]>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/article/?${urlParms}`)
}

export async function searchTaxByCompanyId(companyId:string, search:string): Promise<HttpResponse<TaxType[]>> {
  let urlParms = new URLSearchParams();
  urlParms.append('search', search);

  return get<TaxType[]>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/tax/?${urlParms}`)
}

export async function createUser(body: FormData): Promise<HttpResponse<UserResponse>> {
  return publicPostWithFormData<UserResponse>(`${process.env.REACT_APP_API_URL}/api/v1/user/new`, body);
}

export async function createArticle(companyId: string, body: FormData): Promise<HttpResponse<ArticleResponse>> {
  return postWithFormData<ArticleResponse>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/article/new`, body);
}

export async function createBranch(companyId: string, body: FormData): Promise<HttpResponse<BranchResponse>> {
  return postWithFormData<BranchResponse>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/branch/new`, body);
}

export async function createBranchArea(branchId: BranchType['id'], body: FormData): Promise<HttpResponse<BranchAreaResponse>> {
  return postWithFormData<BranchAreaResponse>(`${process.env.REACT_APP_API_URL}/api/v1/${branchId}/area/new`, body);
}

export async function editBranch(companyId: string, branchId: string, body: FormData): Promise<HttpResponse<BranchResponse>> {
  return postWithFormData<BranchResponse>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/branch/${branchId}/edit`, body);
}

export async function editBranchDeliveryArea(companyId: string, branchId: string, body: FormData): Promise<HttpResponse<BranchResponse>> {
  return postWithFormData<BranchResponse>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/branch/${branchId}/edit/delivery/area`, body);
}

export async function editArea(companyId: string, areaId: string, body: FormData): Promise<HttpResponse<BranchResponse>> {
  return postWithFormData<BranchResponse>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/area/${areaId}/edit`, body);
}

export async function createCompany(body: FormData): Promise<HttpResponse<CompanyResponse>> {
  return postWithFormData<CompanyResponse>(`${process.env.REACT_APP_API_URL}/api/v1/company/new`, body);
}

export async function editCompany(companyId: string, body: FormData): Promise<HttpResponse<CompanyResponse>> {
  return postWithFormData<CompanyResponse>(`${process.env.REACT_APP_API_URL}/api/v1/company/${companyId}/edit`, body);
}

export async function getArticle(companyId: string, id: string): Promise<HttpResponse<ArticleType>> {
  return get<ArticleType>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/article/${id}`)
}

export async function getBranchArticle(companyId: string, id: string): Promise<HttpResponse<ArticleBranchType>> {
  return get<ArticleBranchType>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/branch/article/${id}`)
}

export async function editArticle(companyId: string, articleId: string, body: FormData): Promise<HttpResponse<ArticleResponse>> {
  return postWithFormData<ArticleResponse>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/article/${articleId}/edit`, body);
}

export async function editBranchArticle(companyId: string, branchArticleId: string, body: FormData): Promise<HttpResponse<BranchArticleResponse>> {
  return postWithFormData<BranchArticleResponse>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/branch/article/${branchArticleId}/edit`, body);
}

export async function getBranch(companyId: string, id: string): Promise<HttpResponse<BranchType>> {
  return get<BranchType>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/branch/${id}`)
}

export async function getArea(companyId: CompanyType['id'], areaId: BranchAreaType['id']): Promise<HttpResponse<BranchAreaType>> {
  return get<BranchAreaType>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/area/${areaId}`)
}

export async function disableArticleIngredient(companyId: string, id: string): Promise<HttpResponse<ArticleIngredientsType>> {
  return del<ArticleIngredientsType>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/article/ingredient/${id}`)
}

export async function delArticleImage(companyId: string, id: string): Promise<HttpResponse<ArticleImageDelResponse>> {
  return del<ArticleImageDelResponse>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/article/image/${id}`)
}

export async function searchArticle(companyId: string, search: string): Promise<HttpResponse<ArticleType[]>> {
  let urlParms = new URLSearchParams();
  urlParms.append('search', search);

  return get<ArticleType[]>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/article/?${urlParms}`)
}

export async function searchBranchArticle(companyId: string, branchId: string, search: string): Promise<HttpResponse<ArticleBranchType[]>> {
  let urlParms = new URLSearchParams();
  urlParms.append('search', search);

  return get<ArticleBranchType[]>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/branch/${branchId}/article/?${urlParms}`)
}

export async function getBranchAreas(branchId: BranchType['id']): Promise<HttpResponse<BranchAreaType[]>> {
  return get<BranchAreaType[]>(`${process.env.REACT_APP_API_URL}/api/v1/${branchId}/areas`)
}


export async function searchBranch(companyId: string, search: string): Promise<HttpResponse<BranchType[]>> {
  let urlParms = new URLSearchParams();
  urlParms.append('search', search);

  return get<BranchType[]>(`${process.env.REACT_APP_API_URL}/api/v1/${companyId}/branch/?${urlParms}`)
}

export function logout(): void {
  try {
    cookies.remove('BEARER', { path: '/' });
    window.localStorage.removeItem('companyId');
    window.localStorage.removeItem('branchId');
  } catch (error) {
    console.log(error);
  }
}

export function getToken(): string|null {
  try {
    let token = cookies.get('BEARER');

    return token === undefined ? null : token;
  } catch (error) {
    return null;
  }
}

function setToken(token: string): void {
  try {
    cookies.set('BEARER', token, { path: '/' });
  } catch (error) {
    console.log(error);
  }
}

export function setUser(user: UserType): void {
    try {
        window.localStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
        console.log(error);
    }
}

export function getUser(): UserType | null {
    try {
        let user =  window.localStorage.getItem('user');

        return user ? JSON.parse(user) : null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export function setCompanyId({ id }: Pick<CompanyType, 'id'>): void {
  try {
    window.localStorage.setItem('companyId', id.toString());
  } catch (error) {
    console.log(error);
  }
}

export function getCompanyId(): string | null {
  try {
    return window.localStorage.getItem('companyId');
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function setBranchId({ id }: Pick<BranchType, 'id'>): void {
  try {
    window.localStorage.setItem('branchId', id);
  } catch (error) {
    console.log(error);
  }
}

export function getBranchId(): string | null {
  try {
    return window.localStorage.getItem('branchId');
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function setStaffId({ id }: Pick<StaffType, 'id'>): void {
  try {
    window.localStorage.setItem('staffId', id);
  } catch (error) {
    console.log(error);
  }
}

export function getStaffId(): string | null {
  try {
    return window.localStorage.getItem('staffId');
  } catch (error) {
    console.log(error);
    return null;
  }
}