import React, { FormEvent, useEffect, useContext, useState } from "react";
import {
    useNavigate,
    useParams,
} from "react-router-dom";

import { AuthContext } from "../../context/UserProvider";

import DragAndDrop from "../../layout/dragAndDrop";
import { editBranch, getBranch } from "../../util/Fetch";
import { CompanyActionTypes, CompanyContext } from "../../context/CompanyProvider";

export default function BranchAdd() {
    const { state: { company } } = useContext(AuthContext);
    const CompanyContextData = useContext(CompanyContext);

    let navigate = useNavigate();
    
    const [isInitializing, setIsInitializing] = useState(true);
    const [branchId, setBranchId] = useState<string>('');
    const [branchName, setBranchName] = useState<string>('');
    const [branchStreet, setBranchStreet] = useState<string>('');
    const [branchZip, setBranchZip] = useState<string>('');
    const [branchCity, setBranchCity] = useState<string>('');
    const [branchCountry, setBranchCountry] = useState<string>('');
    const [branchEmail, setBranchEmail] = useState<string>('');
    const [branchTimezone, setBranchTimezone] = useState<string|null>(null);
    const [branchLatitude, setBranchLatitude] = useState<number|null>(null);
    const [branchLongitude, setBranchLongitude] = useState<number|null>(null);
    const [branchPhoneNumber, setBranchPhoneNumber] = useState<string|null>(null);

    const [branchFile1, setBranchFile1] = useState<File|null>(null);
    const [branchImage, setBranchImage] = useState<string|null>(null);
    const [branchHasImage, setBranchHasImage] = useState<boolean>(false);
    
    let params = useParams();

    useEffect(() => {
        const fetchInitialData = async () => {
            if (company && params.branchId) {
                const branchResponse = await getBranch(company.id, params.branchId);

                if (branchResponse.data) {
                    setBranchId(branchResponse.data.id);
                    setBranchName(branchResponse.data.name);
                    setBranchStreet(branchResponse.data.street);
                    setBranchZip(branchResponse.data.zip);
                    setBranchCity(branchResponse.data.city);
                    setBranchCountry(branchResponse.data.country);
                    setBranchEmail(branchResponse.data.email);
                    setBranchTimezone(branchResponse.data.timezone);
                    setBranchLatitude(branchResponse.data.latitude);
                    setBranchLongitude(branchResponse.data.longitude);
                    setBranchImage(branchResponse.data.image);
                    setBranchHasImage(branchResponse.data.image !== null)
                }
            }

            setIsInitializing(false)
        };

        fetchInitialData();
    }, [company, params.branchId]);

    const edit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (company === null) return;

        const formData = new FormData();
        formData.append('branch[name]', branchName);
        formData.append('branch[street]', branchStreet);
        formData.append('branch[zip]', branchZip);
        formData.append('branch[city]', branchCity);
        formData.append('branch[country]', branchCountry);
        formData.append('branch[email]', branchEmail);
        branchTimezone && formData.append('branch[timezone]', branchTimezone);
        branchLatitude && formData.append('branch[latitude]', branchLatitude.toString());
        branchLongitude && formData.append('branch[latitude]', branchLongitude.toString());
        branchPhoneNumber && formData.append('branch[phoneNumber]', branchPhoneNumber);
        formData.append('branch[enabled]', '1');

        formData.append('branch[openTimes][0][dayNumber]', '0');
        formData.append('branch[openTimes][0][startTz]', '2022-08-01 10:00:00');
        formData.append('branch[openTimes][0][endTz]', '2022-08-01 17:00:00');
        formData.append('branch[openTimes][1][dayNumber]', '1');
        formData.append('branch[openTimes][1][startTz]', '2022-08-01 10:00:00');
        formData.append('branch[openTimes][1][endTz]', '2022-08-01 17:00:00');
        formData.append('branch[openTimes][2][dayNumber]', '2');
        formData.append('branch[openTimes][2][startTz]', '2022-08-01 10:00:00');
        formData.append('branch[openTimes][2][endTz]', '2022-08-01 17:00:00');
        formData.append('branch[openTimes][3][dayNumber]', '3');
        formData.append('branch[openTimes][3][startTz]', '2022-08-01 10:00:00');
        formData.append('branch[openTimes][3][endTz]', '2022-08-01 17:00:00');
        formData.append('branch[openTimes][4][dayNumber]', '4');
        formData.append('branch[openTimes][4][startTz]', '2022-08-01 10:00:00');
        formData.append('branch[openTimes][4][endTz]', '2022-08-01 17:00:00');
        formData.append('branch[openTimes][7][dayNumber]', '5');
        formData.append('branch[openTimes][7][startTz]', '2022-08-01 18:00:00');
        formData.append('branch[openTimes][7][endTz]', '2022-08-01 20:00:00');
        formData.append('branch[openTimes][5][dayNumber]', '5');
        formData.append('branch[openTimes][5][startTz]', '2022-08-01 10:00:00');
        formData.append('branch[openTimes][5][endTz]', '2022-08-01 17:00:00');
        formData.append('branch[openTimes][5][status]', '0');
        formData.append('branch[openTimes][6][dayNumber]', '6');
        formData.append('branch[openTimes][6][startTz]', '2022-08-01 10:00:00');
        formData.append('branch[openTimes][6][endTz]', '2022-08-01 17:00:00');


        if (branchFile1) {
            formData.append(`branch[imageFile][file]`, branchFile1);
        }
        
        if (branchImage === null && branchHasImage && branchFile1 === null) {
            formData.append(`branch[imageFile][delete]`, '1');
        }

        try {
            let branch = await editBranch(company.id, branchId, formData);

            if (branch.data && branch.data.status === true && branch.data.data) {
                CompanyContextData.dispatch({ type: CompanyActionTypes.EditBranch, payload: { branch: branch.data.data }})
                navigate('/branches');
            }
        } catch (error) {
            
        }
    };

    if (company === null) {
        return null;
    }

    if (isInitializing === true) {
        return null;
    }

    return (
        <form onSubmit={edit} className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200">
                <div>
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Branch</h3>
                        <p className="mt-1 text-sm text-gray-500">
                        This information will be displayed publicly so be careful what you share.
                        </p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-9">
                        <div className="sm:col-span-9">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={(event) => setBranchName(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={branchName ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-9">
                            <label htmlFor="street" className="block text-sm font-medium text-gray-700">
                                Straße
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="street"
                                    id="street"
                                    onChange={(event) => setBranchStreet(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={branchStreet ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="zip" className="block text-sm font-medium text-gray-700">
                                Zip
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="zip"
                                    id="zip"
                                    onChange={(event) => setBranchZip(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={branchZip ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                Stadt
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    onChange={(event) => setBranchCity(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={branchCity ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                country
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="country"
                                    id="country"
                                    onChange={(event) => setBranchCountry(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={branchCountry ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-9">
                            <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700">
                                Fotos
                            </label>
                            <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                                {branchImage && (
                                    <div className="relative group">
                                        <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden bg-gray-100">
                                            <img src={branchImage} alt={branchName} className="object-center object-cover" />
                                            <div className="flex items-end opacity-0 p-4 group-hover:opacity-100 cursor-pointer" aria-hidden="true">
                                                <div onClick={() => setBranchImage(null)} className="w-full bg-white bg-opacity-75 backdrop-filter backdrop-blur py-2 px-4 rounded-md text-sm font-medium text-gray-900 text-center">
                                                    Delete
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {branchImage === null && (
                                    <DragAndDrop isSingle handleDrop={(files) => setBranchFile1(files[0])} className={''}>
                                        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                            <div className="space-y-1 text-center">
                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg>
                                                <div className="flex text-sm text-gray-600">
                                                    <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                        <span>Upload a file</span>
                                                    </label>
                                                    <p className="pl-1">or drag and drop</p>
                                                </div>
                                                <p className="text-xs text-gray-500">
                                                    PNG, JPG, GIF up to 10MB
                                                </p>
                                            </div>
                                        </div>
                                    </DragAndDrop>
                                )}
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                email
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    onChange={(event) => setBranchEmail(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={branchEmail ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                email
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    onChange={(event) => setBranchPhoneNumber(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={branchPhoneNumber ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="timezone" className="block text-sm font-medium text-gray-700">
                            timezone
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="timezone"
                                    id="timezone"
                                    onChange={(event) => setBranchTimezone(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={branchTimezone ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="lat" className="block text-sm font-medium text-gray-700">
                                lat
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="lat"
                                    id="lat"
                                    onChange={(event) => setBranchLatitude(parseFloat(event.target.value))}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={branchLatitude ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="lat" className="block text-sm font-medium text-gray-700">
                                lng
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="lng"
                                    id="lng"
                                    onChange={(event) => setBranchLongitude(parseFloat(event.target.value))}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={branchLongitude ?? ''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-5">
                <div className="flex justify-end">
                <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Standort speichern
                </button>
                </div>
            </div>
        </form>
    );
}
