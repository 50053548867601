import React, { MouseEvent, ChangeEvent, Component } from 'react'
import heic2any from "heic2any";

import "../css/layout/drag.scss";

class DragAndDrop extends Component<{ isSingle?:boolean, className?:string, handleDrop(files: File[]):void}, {}> {
    dragCounter = 0;
    state = {
        drag: false,
        files: [],
        typesAllowed: ".heic,image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    }

    dropRef = React.createRef() as React.MutableRefObject<HTMLInputElement>;
    fileRef = React.createRef() as React.MutableRefObject<HTMLInputElement> ;

    handleDrag = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }

    handleDragIn = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter++
        if (e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({drag: true})
        }
    }

    handleDragOut = (e: DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        this.dragCounter--
        if (this.dragCounter === 0) {
            this.setState({drag: false})
        }
    }

    handleDrop = async(e: DragEvent) => {
        const { typesAllowed } = this.state;

        let isTypesAllowed = typesAllowed.split(',');

        e.preventDefault()
        e.stopPropagation()
        this.setState({drag: false})

        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.dropRef.current && this.dropRef.current.classList.add('loading');

            let files = Array.from(e.dataTransfer.files);

            files = files.filter((f) => isTypesAllowed.filter((t) => {
                const typeRegex = new RegExp(t, 'g');

                return typeRegex.test(f.type)
            }).length > 0);

            for (let i = 0; i < files.length; i++) {
                files[i] = await this.convertHeicToJpg(files[i]);
            }

            this.props.handleDrop(files);

            e.dataTransfer.clearData()
            this.dragCounter = 0

            this.dropRef.current && this.dropRef.current.classList.remove('loading');

            this.setState({ files });
        }
    }

    handleFile = async (e: ChangeEvent) => {
        const { typesAllowed } = this.state;

        this.dropRef.current && this.dropRef.current.classList.add('loading');

        let isTypesAllowed = typesAllowed.split(',');
        let target = e.target as HTMLInputElement;
        
        let files = Array.from<File>(target?.files ?? []);

        files = files.filter((f) => isTypesAllowed.filter((t) => {
            const typeRegex = new RegExp(t, 'g');

            return typeRegex.test(f.type)
        }).length > 0);

        for (let i = 0; i < files.length; i++) {
            files[i] = await this.convertHeicToJpg(files[i]);
        }

        this.props.handleDrop(files);

        this.dropRef.current && this.dropRef.current.classList.remove('loading');

        this.setState({ files });
    }

    async convertHeicToJpg(file: File): Promise<File>
    {
        let newFile = file;
        if(file.type === "image/heic") {
            let resultBlob = await heic2any({
                blob: file,
                toType: "image/jpg",
            }).catch(() => null);

            if (resultBlob) {
                newFile = new File([resultBlob as BlobPart], file.name.split('.')[0]+".jpg", { type:"image/jpeg", lastModified: new Date().getTime() });
            }
        }

        return newFile;
    }

    componentDidMount()
    {
        let div = this.dropRef.current;
        if (div) {
            div.addEventListener('dragenter', this.handleDragIn);
            div.addEventListener('dragleave', this.handleDragOut);
            div.addEventListener('dragover', this.handleDrag);
            div.addEventListener('drop', this.handleDrop);
        }
    }

    componentWillUnmount()
    {
        let div = this.dropRef.current;
        if (div) {
            div.removeEventListener('dragenter', this.handleDragIn);
            div.removeEventListener('dragleave', this.handleDragOut);
            div.removeEventListener('dragover', this.handleDrag);
            div.removeEventListener('drop', this.handleDrop);
        }
    }

    openFile(e: MouseEvent<HTMLDivElement>)
    {
        e.stopPropagation()

        if (this.fileRef) {
            this.fileRef.current && this.fileRef.current.click();
        }
    }

    delFile()
    {

        this.props.handleDrop([]);
        this.setState({ files: [] });
    }

    render()
    {
        const { className, isSingle } = this.props;
        const { typesAllowed, files } = this.state;

        if (isSingle === true && files.length > 0) {
            return (
                <div
                    className={`file-box img`}
                >
                    <div className={'del-image'} onClick={() => this.delFile()}>löschen</div>
                    <img src={URL.createObjectURL(files[0])} alt={'upload'} />
                </div>
            )
        }

        return (
            <div
                className={className}
                ref={this.dropRef}
                style={{ borderColor: this.state.drag ? '#4681E0' : '#E5E5E5' }}
                onClick={(e) => this.openFile(e)}
            >
                <input type={'file'} accept={typesAllowed} multiple={true} onChange={this.handleFile} ref={this.fileRef} style={{ visibility: 'hidden', display: 'none' }} />
                {this.props.children}
            </div>
        )
    }
}
export default DragAndDrop