import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
} from "react-router-dom";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "./context/UserProvider";
import { ArticleProvider } from "./context/ArticelsProvider";
import { CompanyProvider } from "./context/CompanyProvider";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <CompanyProvider>
        <ArticleProvider>
          <Router>
            <App />
          </Router>
        </ArticleProvider>
      </CompanyProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
