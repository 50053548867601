import React, { createContext, Dispatch, useReducer } from "react";
import { ActionMap, ArticleType } from "../../types";

type ArticleStateType = {
  articles: ArticleType[];
};

enum ArticleActionTypes {
  LoadArticles = "LOAD_ARTICLE",
  AddArticle = "ADD_ARTICLE",
  EditArticle = "EDIT_ARTICLE",
}

type ArticlePayload = {
  [ArticleActionTypes.LoadArticles]: {
    articles: ArticleType[];
  },
  [ArticleActionTypes.AddArticle]: {
    article: ArticleType;
  },
  [ArticleActionTypes.EditArticle]: {
    article: ArticleType;
  }
};

type ArticleActions = ActionMap<ArticlePayload>[keyof ActionMap<ArticlePayload>];

const articleIntialState = {
  articles: [],
};

const articleReducer = (prevState: ArticleStateType, action: ArticleActions) => {
  switch (action.type) {
    case ArticleActionTypes.LoadArticles:
      return {
        ...prevState,
        ...action.payload
      };
    case ArticleActionTypes.AddArticle:
      return {
        ...prevState,
        ...[...prevState.articles, ...[action.payload.article]]
      };
    case ArticleActionTypes.EditArticle:
      let articles = prevState.articles;

      articles = articles.map(a => a.id !== action.payload.article.id ? a : action.payload.article)

      return {
        ...prevState,
        ...articles
      };
    default:
      return prevState;
  }
};

const ArticleContext = createContext<{
  state: ArticleStateType;
  dispatch: Dispatch<ArticleActions>;
}>({
  state: articleIntialState,
  dispatch: () => null,
});

const ArticleProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(articleReducer, articleIntialState);
  return <ArticleContext.Provider value={{ state, dispatch }}>{children}</ArticleContext.Provider>;
};

export { ArticleContext, articleReducer, articleIntialState, ArticleProvider, ArticleActionTypes };
