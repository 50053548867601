import React, { createContext, Dispatch, useReducer } from "react";
import { ActionMap, BranchType, UserType, CompanyType, StaffType } from "../../types";
import { logout, setBranchId, setUser, setCompanyId, setStaffId } from "../util/Fetch";

type AuthType = {
  user: UserType | null;
  company: CompanyType | null;
  branch: BranchType | null;
  staff: StaffType | null;
  userToken: string | null;
  isSignedIn: boolean;
  isSignedOut: boolean;
  isLoading: boolean;
};

enum AuthActionTypes {
  SelectCompany = "SELECT_COMPANY",
  EditCompany = "EDIT_COMPANY",
  SelectBranch = "SELECT_BRANCH",
  SetUser = "SET_USER",
  SetStaff = "SET_STAFF",
  SetLoading = "SET_LOADING",
  Restore = "RESTORE_TOKEN",
  SignIn = "SIGN_IN",
  SignOut = "SIGN_OUT",
}

type AuthPayload = {
  [AuthActionTypes.SelectCompany]: {
    company: CompanyType;
  };
  [AuthActionTypes.EditCompany]: {
    company: CompanyType;
  };
  [AuthActionTypes.SelectBranch]: {
    branch: BranchType;
  };
  [AuthActionTypes.SetStaff]: {
    staff: StaffType;
  };
  [AuthActionTypes.SetUser]: {
    user: UserType;
  };
  [AuthActionTypes.SetLoading]: {
    isLoading: boolean;
  };
  [AuthActionTypes.Restore]: {
    token: string | null;
    company: CompanyType;
    branch: BranchType;
    staff: StaffType;
  };
  [AuthActionTypes.SignIn]: {
    token: string | null;
  };
  [AuthActionTypes.SignOut]: {};
};

type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

const initialState = {
  user: null,
  company: null,
  branch: null,
  staff: null,
  userToken: null,
  isSignedIn: false,
  isSignedOut: false,
  isLoading: true,
};

const authReducer = (prevState: AuthType, action: AuthActions) => {
  switch (action.type) {
    case AuthActionTypes.SelectCompany:
      setCompanyId(action.payload.company);
      return {
        ...prevState,
        company: action.payload.company,
      };
    case AuthActionTypes.SelectBranch:
      setBranchId(action.payload.branch);
      return {
        ...prevState,
        branch: action.payload.branch,
      };
    case AuthActionTypes.EditCompany:
      return {
        ...prevState,
        company: action.payload.company,
      };
    case AuthActionTypes.SetStaff:
        setStaffId(action.payload.staff);
      return {
        ...prevState,
        staff: action.payload.staff,
      };
    case AuthActionTypes.SetUser:
        setUser(action.payload.user);
        return {
            ...prevState,
            user: action.payload.user,
        };
    case AuthActionTypes.Restore:
      return {
        ...prevState,
        company: action.payload.company,
        branch: action.payload.branch,
        staff: action.payload.staff,
        userToken: action.payload.token,
        isSignedIn: true,
        isLoading: false,
      };
    case AuthActionTypes.SetLoading:
      return {
        ...prevState,
        isLoading: action.payload.isLoading,
      };
    case AuthActionTypes.SignIn:
      return {
        ...prevState,
        isSignedIn: true,
        isSignedOut: false,
        userToken: action.payload.token,
      };
    case AuthActionTypes.SignOut:
      logout();
      return {
        ...initialState,
        isSignedOut: true,
        isLoading: false,
      };
    default:
      return prevState;
  }
};

const AuthContext = createContext<{
  state: AuthType;
  dispatch: Dispatch<AuthActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

export { AuthContext, authReducer, initialState, AuthProvider, AuthActionTypes };
