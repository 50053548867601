import React from "react";

import { Navigate } from "react-router-dom";

import { getToken } from "../util/Fetch";

export default function Loading() {
    if (getToken() !== null) return <div>not found</div>

    return <Navigate to="/login" />
}