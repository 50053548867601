import React, { useContext } from "react";

import { AuthContext } from "../context/UserProvider";

export default function User() {
    const { state: { user } } = useContext(AuthContext);

    return (
        <div>
            <h1>{user?.email}</h1>
        </div>
    );
}
