import React, { useEffect, useContext, useState } from "react";
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { AuthContext, AuthActionTypes } from "./context/UserProvider";

import './App.css';
import { getUserData, getCompaniesData, getBranchByCompanyId, getStaffByCompanyId } from "./util/Fetch";
import Layout from "./layout/layout";

import DashBoard from "./screens/DashBoard";
import Articles from "./screens/Articles";
import ArticleAdd from "./screens/Article/Add";
import ArticleEdit from "./screens/Article/Edit";

import Settings from "./screens/Company/Edit";

import Branches from "./screens/Branches";
import BrancheAdd from "./screens/Branch/Add";
import BrancheEdit from "./screens/Branch/Edit";

import Login from "./screens/Login";
import NoMatch from "./screens/NoMatch";
import Loading from "./screens/Loading";

import Create from "./screens/Create/Main";
import CreateUser from "./screens/Create/User";
import CreateCompany from "./screens/Create/Company";
import DeliveryArea from "./screens/Branch/DeliveryArea";


const App = () => {
  const [isInitializing, setIsInitializing] = useState(true);
  const authContext = useContext(AuthContext);
  const { state: { user, isSignedIn }, dispatch } = authContext;

  let location = useLocation();
  let state = location.state as { backgroundLocation?: Location };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await getUserData();
        if (response.data) {
          dispatch({ type: AuthActionTypes.SetUser, payload: { user: response.data } });

          const companiesResponse = await getCompaniesData();
          if (companiesResponse.data && companiesResponse.data.length > 0) {
            dispatch({ type: AuthActionTypes.SelectCompany, payload: { company: companiesResponse.data[0] } });

            const branchesResponse = await getBranchByCompanyId(companiesResponse.data[0].id);
            if (branchesResponse.data && branchesResponse.data.length > 0) {
              dispatch({ type: AuthActionTypes.SelectBranch, payload: { branch: branchesResponse.data[0] } });
            }

            const staffResponse = await getStaffByCompanyId(companiesResponse.data[0].id);
            if (staffResponse.data) {
              dispatch({ type: AuthActionTypes.SetStaff, payload: { staff: staffResponse.data[0] } });
            }
          }
        } else {
          dispatch({ type: AuthActionTypes.SignOut, payload: { } });
        }
      } catch(err) {
      }

      setIsInitializing(false);
    };

    fetchInitialData();
  }, [isSignedIn, dispatch]);

  if (isInitializing) return <div>loading....</div>;

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>
        <>
          {
            user && (
              <Route path="/" element={<Layout />}>
                <Route path="dashboard" element={<DashBoard />} />
                <Route path="settings" element={<Settings />} />
                <Route path="branches" element={<Branches />} />
                <Route path="branch/add" element={<BrancheAdd />} />
                <Route path="branch/:branchId" element={<BrancheEdit />} />
                <Route path="branch/:branchId/delivery/area" element={<DeliveryArea />} />
                <Route path="articles" element={<Articles />} />
                <Route path="article/add" element={<ArticleAdd />} />
                <Route path="article/:articleId" element={<ArticleEdit />} />
              </Route>
            )
          }
          <Route path="create/" element={<Create />} />
          <Route path="create/company" element={<CreateCompany />} />
          <Route path="create/user" element={<CreateUser />} />
          <Route path="login" element={<Login />} />
          <Route path="*" element={<NoMatch />} />
          <Route index element={<Loading />} />
        </>
      </Routes>
      {state?.backgroundLocation && (
        <Routes>
          <Route path="/articles/add" element={<ArticleAdd />} />
        </Routes>
      )}
    </>
  )
}

export default App;
