import React, { useContext, useState } from "react";
import {
    useNavigate,
} from "react-router-dom";

import { AuthActionTypes, AuthContext } from "../../context/UserProvider";
import { createUser, login } from "../../util/Fetch";

export default function CompanyEdit() {
    const { dispatch } = useContext(AuthContext);

    let navigate = useNavigate();
    
    const [userEmail, setUserEmail] = useState<string|null>(null);
    const [userFirstName, setUserFirstName] = useState<string|null>(null);
    const [userSurname, setUserSurname] = useState<string|null>(null);
    const [userPhoneNumber, setUserPhoneNumber] = useState<string|null>(null);
    const [userPassword, setUserPassword] = useState<string|null>(null);

    const create = async () => {
        if (userEmail === null) {
            return;
        }

        if (userPassword === null) {
            return;
        }

        const formData = new FormData();

        userEmail && formData.append('user[email]', userEmail);
        userFirstName && formData.append('user[firstName]', userFirstName);
        userSurname && formData.append('user[surname]', userSurname);
        userPhoneNumber && formData.append('user[phoneNumber]', userPhoneNumber);
        userPassword && formData.append('user[password]', userPassword);
        userPassword && formData.append('user[roles][]', 'ROLE_USER');

        try {
            let createUserResponse = await createUser(formData);

            if (createUserResponse.data && createUserResponse.data.status === true && createUserResponse.data.data) {
                const loginResponse = await login(userEmail, userPassword);

                if (loginResponse.data) {
                    dispatch({ type: AuthActionTypes.SignIn, payload: { token: loginResponse.data.token } });
                    dispatch({ type: AuthActionTypes.SetUser, payload: { user: createUserResponse.data.data } });
                    
                    navigate('/create/company');
                }
            }
        } catch (error) {
            
        }
    };

    return (
        <form onSubmit={create} className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200">
                <div>
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">create user</h3>
                        <p className="mt-1 text-sm text-gray-500">
                        This information will be displayed publicly so be careful what you share.
                        </p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                            <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">
                                firstname
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    onChange={(event) => setUserFirstName(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={userFirstName ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="surname" className="block text-sm font-medium text-gray-700">
                            surname
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="surname"
                                    id="surname"
                                    onChange={(event) => setUserSurname(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={userSurname ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            password
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="password"
                                    id="password"
                                    onChange={(event) => setUserPassword(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={userPassword ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                email
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    onChange={(event) => setUserEmail(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={userEmail ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                phone
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    onChange={(event) => setUserPhoneNumber(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={userPhoneNumber ?? ''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-5">
                <div className="flex justify-end">
                <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    erstellen
                </button>
                </div>
            </div>
        </form>
    );
}
