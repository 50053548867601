import React, { useEffect, useContext, useState } from "react";
import {
    useNavigate,
} from "react-router-dom";

import { AuthActionTypes, AuthContext } from "../../context/UserProvider";
import DragAndDrop from "../../layout/dragAndDrop";
import { editCompany } from "../../util/Fetch";

export default function CompanyEdit() {
    const { state: { company }, dispatch } = useContext(AuthContext);

    let navigate = useNavigate();
    
    const [isInitializing, setIsInitializing] = useState(true);
    const [companyName, setCompanyName] = useState<string>('');
    const [companyDisplayName, setCompanyDisplayName] = useState<string>('');
    const [companyStreet, setCompanyStreet] = useState<string>('');
    const [companyZip, setCompanyZip] = useState<string>('');
    const [companyCity, setCompanyCity] = useState<string>('');
    const [companyCountry, setCompanyCountry] = useState<string>('');
    const [companyEmail, setCompanyEmail] = useState<string>('');
    const [companyCurrency, setCompanyCurrency] = useState<string|null>(null);
    const [companyLocal, setCompanyLocal] = useState<string|null>(null);
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState<string|null>(null);
    
    const [companyFile1, setCompanyFile1] = useState<File|null>(null);
    const [companyImage, setCompanyImage] = useState<string|null>(null);
    const [companyHasImage, setCompanyHasImage] = useState<boolean>(false);
    
    useEffect(() => {
        const fetchInitialData = async () => {
            if (company) {
                setCompanyName(company.name);
                setCompanyDisplayName(company.displayName);
                setCompanyStreet(company.street);
                setCompanyZip(company.zip);
                setCompanyCity(company.city);
                setCompanyEmail(company.email);
                setCompanyCountry(company.country);
                setCompanyCurrency(company.currency);
                setCompanyLocal(company.local);
                setCompanyPhoneNumber(company?.phoneNumber.number ?? null);
                setCompanyImage(company.image);
                setCompanyHasImage(company.image !== null)
            }

            setIsInitializing(false)
        };

        fetchInitialData();
    }, [company]);

    const edit = async () => {
        if (company === null) return;

        const formData = new FormData();

        formData.append('company[name]', companyName);
        formData.append('company[displayName]', companyDisplayName);
        formData.append('company[street]', companyStreet);
        formData.append('company[zip]', companyZip);
        formData.append('company[city]', companyCity);
        formData.append('company[country]', companyCountry);
        companyCurrency && formData.append('company[currency]', companyCurrency);
        companyLocal && formData.append('company[local]', companyLocal);
        
        formData.append('company[email]', companyEmail);
        companyPhoneNumber && formData.append('company[phoneNumber]', companyPhoneNumber);
        formData.append('company[enabled]', '1');

        if (companyFile1) {
            formData.append(`company[imageFile][file]`, companyFile1);
        }
        
        if (companyImage === null && companyHasImage && companyFile1 === null) {
            formData.append(`company[imageFile][delete]`, '1');
        }

        try {
            let editCompanyResponse = await editCompany(company.id, formData);

            if (editCompanyResponse.data && editCompanyResponse.data.status === true && editCompanyResponse.data.data) {
                dispatch({ type: AuthActionTypes.EditCompany, payload: { company: editCompanyResponse.data.data } });
                navigate('/dashboard');
            }
        } catch (error) {
            
        }
    };

    if (company === null) {
        return null;
    }

    if (isInitializing === true) {
        return null;
    }

    return (
        <form onSubmit={edit} className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200">
                <div>
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Company</h3>
                        <p className="mt-1 text-sm text-gray-500">
                        This information will be displayed publicly so be careful what you share.
                        </p>
                    </div>
                    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-9">
                        <div className="sm:col-span-9">
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    onChange={(event) => setCompanyName(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={companyName ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-9">
                            <label htmlFor="display-name" className="block text-sm font-medium text-gray-700">
                                Display Name
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="display-name"
                                    id="display-name"
                                    onChange={(event) => setCompanyDisplayName(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={companyDisplayName ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="street" className="block text-sm font-medium text-gray-700">
                                Street
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="street"
                                    id="street"
                                    onChange={(event) => setCompanyStreet(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={companyStreet ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="zip" className="block text-sm font-medium text-gray-700">
                                Zip
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="zip"
                                    id="zip"
                                    onChange={(event) => setCompanyZip(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={companyZip ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                City
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="city"
                                    id="city"
                                    onChange={(event) => setCompanyCity(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={companyCity ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                            country
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="country"
                                    id="country"
                                    onChange={(event) => setCompanyCountry(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={companyCountry ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-9">
                            <label htmlFor="cover-photo" className="block text-sm font-medium text-gray-700">
                                Fotos
                            </label>
                            <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-4">
                                {companyImage && (
                                    <div className="relative group">
                                        <div className="aspect-w-4 aspect-h-3 rounded-lg overflow-hidden bg-gray-100">
                                            <img src={companyImage} alt={companyName} className="object-center object-cover" />
                                            <div className="flex items-end opacity-0 p-4 group-hover:opacity-100 cursor-pointer" aria-hidden="true">
                                                <div onClick={() => setCompanyImage(null)} className="w-full bg-white bg-opacity-75 backdrop-filter backdrop-blur py-2 px-4 rounded-md text-sm font-medium text-gray-900 text-center">
                                                    Delete
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {companyImage === null && (
                                    <DragAndDrop isSingle handleDrop={(files) => setCompanyFile1(files[0])} className={''}>
                                        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                            <div className="space-y-1 text-center">
                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg>
                                                <div className="flex text-sm text-gray-600">
                                                    <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                        <span>Upload a file</span>
                                                    </label>
                                                    <p className="pl-1">or drag and drop</p>
                                                </div>
                                                <p className="text-xs text-gray-500">
                                                    PNG, JPG, GIF up to 10MB
                                                </p>
                                            </div>
                                        </div>
                                    </DragAndDrop>
                                )}
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            email
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    onChange={(event) => setCompanyEmail(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={companyEmail}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                            phone
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    onChange={(event) => setCompanyPhoneNumber(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={companyPhoneNumber ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                            currency
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="currency"
                                    id="currency"
                                    onChange={(event) => setCompanyCurrency(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={companyCurrency ?? ''}
                                />
                            </div>
                        </div>
                        <div className="sm:col-span-3">
                            <label htmlFor="local" className="block text-sm font-medium text-gray-700">
                            local
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <input
                                    type="text"
                                    name="local"
                                    id="local"
                                    onChange={(event) => setCompanyLocal(event.target.value)}
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-5 pr-12 sm:text-sm border-gray-300 rounded-md"
                                    value={companyLocal ?? ''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-5">
                <div className="flex justify-end">
                <button
                    type="submit"
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    speichern
                </button>
                </div>
            </div>
        </form>
    );
}
