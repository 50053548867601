import React, { useContext, useEffect } from "react";

import { AuthContext } from "../context/UserProvider";
import { useNavigate } from "react-router-dom";

import { ArticleActionTypes, ArticleContext } from "../context/ArticelsProvider";
import { searchArticle } from "../util/Fetch";

export default function Articles() {
    const AuthContextData = useContext(AuthContext);
    const { state: { articles }, dispatch } = useContext(ArticleContext);

    const { company } = AuthContextData.state;

    let navigate = useNavigate();


    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                if (company) {
                    const articleResponse = await searchArticle(company.id, '');
                    if (articleResponse.data) {
                        dispatch({ type: ArticleActionTypes.LoadArticles, payload: { articles: articleResponse.data } });
                    } else {
                        //AuthContextData.dispatch({ type: AuthActionTypes.SignOut, payload: { } });
                    }
                }
            } catch(err) {
            }
        };

        fetchInitialData();
    }, [dispatch, company, AuthContextData]);

    if (company === null) {
        return null;
    }

    return (
        <div className="">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">Products</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Products Products Products Products
                    </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                        type="button"
                        onClick={() => navigate('/article/add')}
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                        add Product
                    </button>
                </div>
            </div>
            <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Name
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                                Category
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Preis
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        { articles.map((article) => (
                            <tr key={article.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {article.name}
                                </td>
                                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                    {article.category.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {new Intl.NumberFormat(company.local, { style: 'currency', currency: company.currency }).format(article.price)}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <a href="#" onClick={() => navigate(`/article/${article.id}`)} className="text-indigo-600 hover:text-indigo-900">
                                        Edit<span className="sr-only">, {article.name}</span>
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
